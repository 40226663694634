<template>
    <div class="home">
        <div class="top-bar"><a href="tel:+17164790210">(716) 479-0210</a><a href="elite.caregivers@mail.com">wes.care@mail.com</a></div>
        <div class="header">
            <div class="row">
                <div class="col-50">
                    <img class="header-logo" src="@/assets/logo.jpeg" />
                </div>
                <div class="col-50">
                    <div class="header-text">
                        <h2>Non-Emergency Medical Transportation</h2>
                    </div>
                    <img class="dbe" src="@/assets/dbe.png" />
                    <button class="header-btn" onclick="window.location.href='tel:+17164790210'">CALL NOW</button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="row">
                <div class="col-50">
                    <img class="side-pic" src="@/assets/transport.jpg" />
                    <div class="row button-row">
                        <div class="col-50">
                            <button class="body-btn" onclick="window.open('http://wescaretransport.ninjawebsitedesign.com/brochure.pdf')">Brochure</button>
                        </div>
                        <div class="col-50">
                            <button class="body-btn" onclick="window.open('http://wescaretransport.ninjawebsitedesign.com/businesscard.pdf')">Business Card</button>
                        </div>
                    </div>
                </div>
                <div class="col-50">
                    <div class="body-text-wrapper">
                        <div class="body-text">
                            <h2>Who We Are</h2>
                            <img src="@/assets/van.jpeg" />
                            <p>
                                Founded in 2019, WesCare Transportation based out of Niagara Falls, NY, provides first rate non-emergency medical transportation services throughout western New York and surrounding areas. Are you or one of your loved ones in need of reliable, dependable and on-time non-emergency medical transportation? Look no further. You have just reached your destination!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about">
                <div class="about-content">
                    <h2 class="about-title">What We Do</h2>
                    <img src="@/assets/pic2.jpeg" />
                    <p>
                        WesCare Transportation provides non-emergency medical transportation to individuals and families for <span style="text-decoration: underline solid darkred;">All</span> Medically necessary appointments.
                    </p>
                    <p>
                        We also provide "Clinical Trial Transportation" for clients participating in clinical trial research.
                    </p>
                </div>
            </div>
            <div class="choices">
                <div class="choices-content">
                    <h2>Why Choose WesCare?</h2>
                    <h4>Services Include ...</h4>
                    <img class="choices-logo" src="@/assets/logo.jpeg" />
                    <h2 class="free-title">All Rides are Free!</h2>
                    <h6>If you have Medicaid, Fidelis Care, Essential Plan Program, Medicare Advantage, Managed Long Term Care, United Health Benefits Exchange &amp; Essential Plan Program ...</h6>
                    <br />
                    <h6>1-800-651-7040 (MAS/Medicare)</h6>
                    <h6>1-888-343-3547 (Fideliscare)</h6>
                    <br />
                    <h6>If you need help setting up your <span style="color: red; font-weight: 900;">FREE RIDE</span> call WesCare Transportation</h6>
                    <h6>716-479-0210</h6>
                    <br />
                    <img src="@/assets/pic3.jpeg" />
                    <br />
                    <hr class="center-border" />
                    <h3>Non-Emergency Medical Transportation</h3>
                    <h5>We Are Committed to Safety, Customer Service, &amp; Punctuality</h5>
                    <ul class="choices-menu">
                        <li>Clinical Trial Transportation</li>
                        <li>Chemotherapy Treatments</li>
                        <li>Radiation Treatments</li>
                        <li>Covid-19 Testing</li>
                        <li>Covid-19 Vaccine Appointments</li>
                        <li>Doctor&#39;s Appointments</li>
                        <li>Dialysis Treatment</li>
                        <li>Physical Therapy</li>
                        <li>Mental Health</li>
                        <li>Substance Abuse Counseling</li>
                        <li>Adult Day Care Facilities</li>
                        <li>Adult Living Facilities</li>
                        <li>Pharmecy Prescription Pickup &amp; Drop Off</li>
                    </ul>
                    <h3>Proven Benefits Are ...</h3>
                    <div class="row choices-image-row">
                        <img src="@/assets/extra.jpeg" />
                        <img src="@/assets/marketing.jpeg" />
                    </div>
                    <ul class="choices-menu">
                        <li>Improvement in appointment adherence</li>
                        <li>Increase in clinic revenue</li>
                        <li>Healthier clients and fewer emergency visits</li>
                        <li>Transportation for clients is a valuable service that can reduce physical, mental and financial hardships</li>
                        <li>Transporting clients to and from appointments is a great way to avoid health risks and reach more people</li>
                        <li>Logistical transportation benefits clinical trials and ensures consistent data and accurate results</li>
                        <li>Allows us to manage appointment travel so you don&#39;t have to</li>
                    </ul>
                    <img class="family" src="@/assets/family.jpeg" />
                </div>
            </div>
            <h2 class="contact-title">Contact WesCare for More Information</h2>
            <div class="row contact-row">
                <div class="col-50">
                    <img src="@/assets/pic1.jpeg" />
                </div>
                <div class="col-50">
                    <form class="contact" id="contact-form" v-on:submit.prevent="sendEmail()">
                        <div class="row">
                            <div class="col-33">
                                <input type="text" name="name" placeholder="Name" />
                            </div>
                            <div class="col-33">
                                <input type="email" name="email" placeholder="Email" />
                            </div>
                            <div class="col-33">
                                <input type="tel" name="phone" placeholder="Phone" />
                            </div>
                        </div>
                        <input class="address" type="text" name="address" placeholder="Address" />
                        <textarea rows="10" name="message" placeholder="Your Message ..."></textarea>
                        <input type="submit" value="Send Message" />
                    </form>
                </div>
            </div>
        </div>
        <footer class="footer">
            <div class="row">
                <div class="col-33">
                    <img class="footer-logo" src="@/assets/logo.jpeg" />
                    <p>&copy; WesCare Transportation LLC 2021</p>
                </div>
                <div class="col-33">
                    <h2>Contact</h2>
                    <p>
                        Niagara Falls, NY 14301<br />
                        (716) 479-0210<br />
                        wes.care@mail.com
                    </p>
                </div>
                <div class="col-33">
                    <img class="cards" src="@/assets/cards.jpeg" />
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import emailjs from 'emailjs-com'
    export default {
        methods: {
            sendEmail() {

                emailjs

                    .sendForm(
                        "service_tberrxk",
                        "template_h542r3s",
                        "#contact-form",
                        "user_tJQeT8oPh8t2hKaVxbHoR"
                    )

                    .then(
                        result => {
                            return alert("Message has been sent. We'll get back to you shortly"), result;
                        },

                        error => {
                            alert(error);
                            return console.error();
                        }
                );
            }
        }
    }
</script>

<style>
    .home .header {
        background: darkblue;
        padding: 20px;
        border-bottom: 6px solid white;
    }

    .home .top-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px;
        background: black;
    }

    .home .body .button-row {
        justify-content: center;
    }

    .home .body .body-btn {
        margin: auto;
        width: 175px;
        display: block;
        border: medium solid white;
        box-shadow: 0 4px 12px rgba(0,0,0,.15);
        background: black;
        color: white;
        font-family: 'Prompt', sans-serif;
        padding: 12px;
        font-size: 1em;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 20px;
    }

        .home .body .body-btn:hover {
            transition: 0.5s ease;
            background: rgb(64,64,64);
        }

    .home .choices img {
        width: 720px;
        height: auto;
        display: block;
        margin: auto;
    }

    .home .choices .choices-logo {
        width: 200px;
    }

    .home .choices .choices-image-row {
        width: 80%;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
    }

        .home .choices .choices-image-row img {
            width: 45%;
            object-fit: contain;
            justify-content: center;
            margin: 0;
            padding: 20px;
            margin-bottom: 50px;
        }

        .home .top-bar a {
            color: white;
            font-family: 'Prompt', sans-serif;
            font-weight: 900;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
        }

    .home .header .row {
        width: 60%;
        justify-content: center;
        margin: auto;
    }

    .home .header .row .img {
        width: 100%;
        margin: auto;
    }

        .home .header .row .header-logo {
            background: white;
            width: 90%;
            margin: auto;
            display: block;
            padding: 20px;
            border-radius: 10px;
        }

        .home .header .row .header-text h2 {
            color: white;
            padding: 0 20px 0 20px;
            text-transform: uppercase;
            font-weight: 900;
            text-align: center;
            font-family: 'Prompt', sans-serif;
        }

        .home .header .row .header-text p {
            width: 60%;
            margin: auto;
            display: block;
            color: white;
            text-align: center;
            font-weight: 500;
            font-family: 'Prompt', sans-serif;
        }

        .home .header .row .dbe {
            width: 25%;
            margin: 20px auto;
            display: block;
        }

        .home .header .row .header-btn {
            background: white;
            font-weight: 900;
            border: thick solid white;
            padding: 5px;
            font-size: 1.5em;
            font-family: 'Prompt', sans-serif;
            color: rgb(32,32,32);
            margin: auto;
            margin-top: 20px;
            width: 300px;
            display: block;
            cursor: pointer;
        }

        .home .header .row .header-btn:hover {
            transition: 0.5s ease;
            background: rgb(255 219 219);
            transform: scale(0.95);
        }

        .home .body .side-pic {
            width: 100%;
            margin: auto;
            border-bottom-right-radius: 50px;
            box-shadow: 0 4px 24px rgba(0,0,0,.35);
        }

    .home .body .row .body-text-wrapper .body-text {
        padding-top: 3vw;
        width: 60%;
        margin: auto;
        display: block;
    }

    .home .body .about {
        padding-top: 1vw;
        padding-bottom: 3vw;
        background-color: darkblue;
    }

    .home .body .about img {
        width: 25%;
        margin: auto;
        margin-bottom: 50px;
        display: block;
        border-radius: 50%;
        border: 6px solid white;
        box-shadow: 0 4px 12px rgba(0,0,0,.35);
    }

    .home .body .body-text img {
        width: 200px;
        margin: auto;
        display: block;
    }

    .home .body .choices .row {
        width: 95%;
        margin: auto;
    }

    .home .body .choices .choices-menu {
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        width: 350px;
        margin: auto;
        margin-bottom: 50px;
        display: block;
    }

    .home .body .choices p {
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        width: 60%;
        font-size: 1.15em;
        margin: auto;
        border-radius: 10px;
        display: block;
        text-align: center;
        margin-bottom: 25px;
        background: white;
        color: black;
        box-shadow: 0 4px 12px rgba(0,0,0,.15);
        padding: 20px;
    }

        .home .body .choices .free-title {
            color: red;
        }

        .home .body .choices .col-50 {
            display: block;
        }

    .home .body .choices h3 {
        font-size: 3em !important;
        text-align: center;
        font-weight: bolder;
        margin-bottom: 0 !important;
        font-family: 'Prompt', sans-serif;
        font-weight: 900;
    }

    .home .body .choices h4 {
        font-size: 1.5em !important;
        text-align: center;
        font-weight: bolder;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-family: 'Prompt', sans-serif;
        font-weight: 900;
    }

    .home .body .choices h6 {
        font-size: 1em !important;
        text-align: center;
        font-weight: bolder;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
    }

    .home .body .center-border {
        border-width: 3px;
        border-style: solid;
        width: 60%;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 25px;
        display: block;
    }

    .home .body .choices h5 {
        font-size: 2em;
        text-align: center;
        margin-top: 0 !important;
        font-weight: bolder;
        text-transform: uppercase;
        font-family: 'Prompt', sans-serif;
        font-weight: 900;
    }

    .home .contact-row img {
        width: 100%;
        margin: auto;
        margin-bottom: 0;
        border-top-right-radius: 50px;
    }

    .home .body .contact-title, .home .body .about-title, .home .choices h2 {
        font-weight: bolder;
        text-transform: uppercase;
        text-align: center;
        padding: 20px;
        font-family: 'Prompt', sans-serif;
        font-weight: 900;
    }

        .home .body .about-title {
            color: white;
        }

        .home .choices h2 {
            margin-bottom: 0;
            padding: 0 20px 0 20px;
        }

    .home .choices h3 {
        margin-top: 0;
        padding: 0 20px 0 20px;
    }

    .home .choices {
        margin-bottom: 50px;
    }

    .home .body .row .body-text-wrapper .body-text h2 {
        text-align: center;
        color: black;
        font-weight: 900;
        font-family: 'Prompt', sans-serif;
    }
    .home .body .row .body-text-wrapper .body-text p, .home .body .about p {
        text-align: center;
        color: black;
        font-weight: 500;
        font-family: 'Prompt', sans-serif;
    }

    .home .body .about p {
        padding: 0 20px 0 20px;
        color: white;
    }

    .home .body .about {
        margin-bottom: 50px;
    }

    .home .body .contact .row {
        width: 80%;
        justify-content: center;
        margin: auto;
        text-align-last: center;
    }

    .home .body .contact input {
        padding: 20px;
        border: none;
        box-shadow: 0 4px 9px rgba(0,0,0,.15);
        margin-bottom: 20px;
        width: 50%;
        font-weight: 900;
        font-family: 'Prompt', sans-serif;
    }
    .home .body .contact textarea {
        padding: 20px;
        border: none;
        box-shadow: 0 4px 9px rgba(0,0,0,.15);
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
        resize: none;
        display: block;
        font-weight: 900;
        font-family: 'Prompt', sans-serif;
    }

    .home .body .contact .address {
        margin: auto;
        width: 80%;
        margin-bottom: 20px;
        display: block;
        text-align-last: center;
        font-weight: 900;
        font-family: 'Prompt', sans-serif;
    }

    .home .body .contact input[type="submit"] {
        margin: auto;
        display: block;
        background: rgb(64,64,64);
        color: white;
        font-weight: 900;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 20px;
        font-family: 'Prompt', sans-serif;
    }

        .home .body .contact input[type="submit"]:hover {
            transition: 0.5s ease;
            background: black;
        }

        .home .footer {
            background-color: darkblue;
            color: white;
            padding-top: 3vw;
            padding-bottom: 3vw;
        }

    .home .footer .row {
        width: 60%;
        margin: auto;
    }

        .home .footer h2 {
            text-align: center;
            font-family: 'Prompt', sans-serif;
            font-weight: 900;
        }

    .home .footer p {
        text-align: center;
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: bolder;
    }

    .home .footer .footer-logo, .home .footer .cards {
        width: 180px;
        padding: 10px;
        background: white;
        margin: auto;
        display: block;
        border-radius: 10px;
    }

    h2 {
        font-size: 2em !important;
        text-transform: none !important;
    }

    h3 {
        font-size: 1.5em !important;
        text-transform: none !important;
    }

    @media ( max-width: 1400px ) {
        .home .header .row {
            width: 95%;
            margin: auto;
        }
    }

    @media ( max-width: 1080px ) {
        .row {
            width: auto !important;
            flex-direction: column !important;
        }
        .footer .col-33 {
            width: auto;
        }
        .home .contact-row img {
            border-top-right-radius: 0;
        }
        .home .body .choices h3 {
            font-size: 1.5em !important;
        }
        .home .body .choices h5 {
            font-size: 1em !important;
            padding: 20px;
        }
            .home .body .choices h6 {
                padding-left: 20px;
                padding-right: 20px;
            }
            .home .body .side-pic {
                border-bottom-right-radius: 0;
            }
        .home .choices .choices-image-row img {
            align-self: center;
            width: 95%;
            margin-bottom: 20px;
        }
        .home .body .contact .address {
            width: 90%;
        }
        .home .header .row .header-logo {
            width: 200px;
        }
        .footer .row, .choices .row {
            width: 95% !important;
        }
        .home .body .button-row {
            flex-direction: row !important;
        }
        .home .header .row {
            width: auto;
        }
        .home .body .contact {
            margin-top: 20px;
        }
        .row .col-50 {
            width: auto !important;
            margin: auto !important;
        }
        .home .body .contact textarea, .home .body .address {
            width: 80%;
        }
        .home .body .contact .row {
            flex-direction: row !important;
        }
        .home .body .contact textarea {
            width: 90%;
            margin: auto;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 1.5em !important;
        }
        h3 {
            font-size: 1em !important;
        }
        .home .body .center-border {
            border-width: 1px;
            width: 95%;
        }
        .home .body .about img {
            width: 80%;
        }
    }

    @media ( max-width: 700px ) {
        .choices .row {
            width: 80% !important;
        }
        .home .choices .choices-image-row img {
            width: 95%;
        }
        .choices .choices-menu {
            width: 75% !important;
        }
        .home .choices img {
            width: 100%;
        }
        .choices p {
            width: 75% !important;
            margin: auto;
        }
    }

    @media ( max-width: 597px ) {
        .home .body .contact .row {
            flex-direction: column !important;
            justify-content: center;
            text-align-last: center;
        }

        .home .top-bar {
            flex-direction: column !important;
            text-align: center !important;
        }

        .home .choices .choices-image-row img {
            width: 95%;
        }

        .choices .row {
            width: 80% !important;
        }

        .home .body .contact .address {
            width: 80%;
        }

        .choices .row .choices-menu {
            padding: 0;
        }

        .home .header .row .header-logo {
            width: 75%;
        }

        h2 {
            font-size: 1em;
        }

        .footer .row, .home .body .button-row {
            flex-direction: column !important;
        }

        .home .footer .row :nth-child(1) {
            order: 3;
            margin-top: 50px;
        }

        .home .footer .row :nth-child(2) {
            order: 2;
        }

        .home .footer .row :nth-child(3) {
            order: 1;
        }

        .row .col-33 {
            width: auto !important;
            margin: auto !important;
        }

        .home .header .row .header-btn {
            width: 95%;
        }

        .home .header .row .header-text p, .home .body .row .body-text-wrapper .body-text {
            width: 95%;
        }

        .home .body .contact .row .col-33 {
            width: 95%;
            margin: auto;
        }

        .home .body .contact input {
            width: 80%;
        }

        .home .body .contact textarea {
            width: 80%;
            margin: auto;
            margin-bottom: 20px;
        }
    }
</style>